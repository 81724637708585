var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"cols":12}},[_c('h3',{staticClass:"text-h5 my-4"},[_vm._v(_vm._s(_vm.title))]),_c('v-card',[_c('v-card-title',[_c('DatePickerInput',{attrs:{"prepend-inner-icon":"mdi-calendar-range","className":"shrink mr-3","range":_vm.range,"show-week":_vm.weekDatePicker,"readonly":""},on:{"input":_vm.setDates},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}}),(_vm.enableUpload)?_c('FileUploadModal',{ref:"modal",attrs:{"title":"Ręczne ładowanie raportu","formats":".xlsx","pending":_vm.uploadInProgress},on:{"uploadedFile":function($event){return _vm.upload($event)}}}):_vm._e(),_c('v-spacer'),_c('v-text-field',{staticClass:"shrink",attrs:{"append-icon":"mdi-magnify","label":"Wyszukaj"},on:{"blur":_vm.fetchItems},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.$data.$_paginationMixin_options,"item-key":"id","loading":_vm.$data.$_paginationMixin_fetchingItems,"search":_vm.search,"items-per-page":_vm.$data.$_paginationMixin_itemsPerPage,"page":1,"server-items-length":_vm.$data.$_paginationMixin_totalCount,"footer-props":{
        'items-per-page-options': _vm.$data.$_paginationMixin_rowsPerPageOptions,
      }},on:{"update:options":function($event){return _vm.$set(_vm.$data, "$_paginationMixin_options", $event)}},scopedSlots:_vm._u([{key:"item.product",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.product.name)+" ")]}},{key:"item.sku",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.product.sku)+" ")]}},{key:"item.saledValue",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.saledValue,2, 4))+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }