<template>
  <v-col :cols="12">
    <h3 class="text-h5 my-4">{{ title }}</h3>
    <v-card>
      <v-card-title>
        <DatePickerInput
          prepend-inner-icon="mdi-calendar-range"
          className="shrink mr-3"
          :range="range"
          @input="setDates"
          :show-week="weekDatePicker"
          readonly
          v-model="dateRange"
        />
        <FileUploadModal
          v-if="enableUpload"
          @uploadedFile="upload($event)"
          title="Ręczne ładowanie raportu"
          formats=".xlsx"
          ref="modal"
          :pending="uploadInProgress"
        />
        <v-spacer />
        <v-text-field
          append-icon="mdi-magnify"
          class="shrink"
          @blur="fetchItems"
          v-model="search"
          label="Wyszukaj"
        />
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="$data.$_paginationMixin_options"
        item-key="id"
        :loading="$data.$_paginationMixin_fetchingItems"
        :search="search"
        :items-per-page="$data.$_paginationMixin_itemsPerPage"
        :page="1"
        :server-items-length="$data.$_paginationMixin_totalCount"
        :footer-props="{
          'items-per-page-options': $data.$_paginationMixin_rowsPerPageOptions,
        }"
      >
        <template v-slot:item.product="{item}">
          {{ item.product.name }}
        </template>
        <template v-slot:item.sku="{item}">
          {{ item.product.sku }}
        </template>
        <template v-slot:item.saledValue="{item}">
          {{ item.saledValue | currency(2, 4) }}
        </template>
      </v-data-table>
    </v-card>
  </v-col>
</template>

<script>
import paginationMixin from '@/mixins/pagination.mixin'
import DatePickerInput from '@/components/UI/DatePickerInput'
import FileUploadModal from '@/components/Modals/FileUploadModal'
import getWeekByDay from '@/utils/getWeekByDay'
import alertPresets from '@/utils/reports/alertConfig'
import RepositoryFactory from '@/repositories/RepositoryFactory'

import {mapActions} from 'vuex'

const ReportsRepository = RepositoryFactory.get('reports')
const {methods} = ReportsRepository

export default {
  mixins: [paginationMixin],
  props: {
    title: {
      type: String,
      required: true,
    },
    ceneoChannel: {
      type: Boolean,
      required: true,
    },
    enableUpload: {
      type: Boolean,
      required: true,
    },
    weekDatePicker: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    DatePickerInput,
    FileUploadModal,
  },
  name: 'Exactag',
  data() {
    return {
      search: '',
      uploadInProgress: false,
      dateRange: [],
      range: true,
      items: [],
      headers: [
        {text: 'Nazwa produktu', value: 'product', width: '75%'},
        {text: 'SKU', value: 'sku', width: '10%'},
        {text: 'Wartość sprzedaży', value: 'saledValue', width: '15%'},
      ],
    }
  },
  computed: {
    searchOptions() {
      return {
        ...this.$data.$_paginationMixin_options,
        dateRange: this.dateRange,
      }
    },
  },
  watch: {
    searchOptions: {
      handler() {
        this.fetchItems()
      },
      deep: true,
    },
  },
  created() {
    this.setDates()
  },
  methods: {
    setDates(d) {
      let date = d ? new Date(d.shift()) : new Date()
      if (this.weekDatePicker) {
        date.setFullYear(new Date().getFullYear() - 1)
      }
      date = date.toISOString().slice(0, 10)
      this.dateRange = getWeekByDay([date])
    },
    ...mapActions(['getReportHistory', 'uploadReport', 'showNotification']),
    async fetchItems() {
      const {searchOptions, search} = this
      const {dateRange} = searchOptions
      this.$data.$_paginationMixin_fetchingItems = true
      const options = {
        CeneoChannel: this.ceneoChannel,
        dateFrom: dateRange[0],
        dateTo: dateRange[1],
      }
      const query = this.$_paginationMixin_createQuery(
        {
          ...this.$data.$_paginationMixin_options,
          search,
        },
        options
      )
      const {data} = await methods.getReportHistory('EXACTAG', query)
      const {totalCount, exactagHistories} = data
      this.$data.$_paginationMixin_totalCount = totalCount
      this.items = exactagHistories
      this.$data.$_paginationMixin_fetchingItems = false
    },
    async upload(formData) {
      try {
        this.uploadInProgress = true
        await this.uploadReport({
          report: 'EXACTAG',
          formData,
        })
        this.$swal(alertPresets.success)
        this.uploadInProgress = false
        this.$refs.modal.close()
      } catch (e) {
        const {response} = e
        this.showNotification({
          type: 'error',
          message: response.data,
        })
        this.uploadInProgress = false
      }
      this.$refs.modal.file = null
    },
  },
}
</script>

<style scoped></style>
