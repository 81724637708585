<template>
  <div>
    <ExactagTable
      :week-date-picker="false"
      :enable-upload="true"
      :ceneo-channel="true"
      title="Raport Exactag sprzedaży dla Ceneo"
    />
    <ExactagTable
      :week-date-picker="false"
      :enable-upload="true"
      :ceneo-channel="false"
      title="Raport Exactag sprzedaży ze wszystkich kanałów"
    />
    <ExactagTable
      :week-date-picker="true"
      :enable-upload="false"
      :ceneo-channel="true"
      title="Raport Exactag sprzedaży historycznej dla Ceneo"
    />
    <ExactagTable
      :week-date-picker="true"
      :enable-upload="false"
      :ceneo-channel="false"
      title="Raport Exactag sprzedaży historycznej ze wszystkich kanałów"
    />
  </div>
</template>

<script>
import ExactagTable from '@/components/Reports/ExactagTable'

export default {
  components: {
    ExactagTable,
  },
  name: 'Exactag',
}
</script>

<style scoped></style>
